<template>
  <div class="w-full h-full">
    <div
      class="w-full h-full all p-5 pl-6 pr-6 cursor-pointer items-center"
      @click="selected"
      data-aos="fade-right"
      data-aos-duration="1000"
    >
      <div class="web flex">
        <div class="w-full flex items-center pr-4">
          <div class="nom w-3/5">
            <div class="enonce">
              Police : 20270023
            </div>

            <div class="flex mt-1">
              <img src="../../../assets/icons/auto.svg" alt="" class="h-4 w-4 mr-2">
              <div class="type">Assurance Automobile</div>
            </div>

<!--            <div class="flex mt-1">-->
<!--              <img src="../../../assets/icons/moto.svg" alt="" class="h-4 w-4 mr-2">-->
<!--              <div class="type">Assurance Moto</div>-->
<!--            </div>-->

<!--            <div class="flex mt-1">-->
<!--              <img src="../../../assets/icons/voyage.svg" alt="" class="h-4 w-4 mr-2">-->
<!--              <div class="type">Assurance Voyage</div>-->
<!--            </div>-->

<!--            <div class="flex mt-1">-->
<!--              <img src="../../../assets/icons/mrh.svg" alt="" class="h-4 w-4 mr-2">-->
<!--              <div class="type">Assurance Mrh</div>-->
<!--            </div>-->

            <div class="flex mt-1 resultat">
              <div class=" mr-2">
                BMW X6
              </div>

              <div class="activeStatus"> Active </div>
<!--              <div class="inactiveStatus" v-if="item.status === 'PAID' || item.paymentStatus === 'PAID'">Payé</div>-->
<!--              <div class="activeStatus" v-if="item.status === 'PENDING'">Traitement en cours</div>-->
<!--              <div class="activeStatus" v-if="item.status === 'DONE' && item.paymentStatus !== 'PAID'">Reccevable</div>-->
            </div>
          </div>

          <div class="nom w-2/5">
            <div class="flex mt-1 resultat">
              Montant
            </div>
            <div class="mt-2">
<!--              <span >0</span>-->
              <span >4 700</span> Fr
            </div>
          </div>

          <div class="w-1/5 flex justify-center">
              <div class="mr-2 text-sm">
                11-10-2021, 11h20
              </div>
          </div>
        </div>

      </div>

      <div class="mobile">
        <div class="flex items-center">

          <div class="nom w-2/3 mt-3 mr-4">
            <div class="enonce">
              Police: 39884424
            </div>

            <div class="mt-2">
              <div class="flex mt-1">
                <img src="../../../assets/icons/auto.svg" alt="" class="h-4 w-4 mr-2">
                <div class="type">Assurance Automobile</div>
              </div>

<!--              <div class="flex mt-1" v-if="item.body.subscriptionData.product.categorie === 'MOTO' || item.body.subscriptionData.product.categorie === 'Moto'">-->
<!--                <img src="../../../assets/icons/moto.svg" alt="" class="h-4 w-4 mr-2">-->
<!--                <div class="type">Assurance Moto</div>-->
<!--              </div>-->

<!--              <div class="flex mt-1" v-if="item.body.subscriptionData.product.categorie === 'VOYAGE' || item.body.subscriptionData.product.categorie === 'Voyage'">-->
<!--                <img src="../../../assets/icons/voyage.svg" alt="" class="h-4 w-4 mr-2">-->
<!--                <div class="type">Assurance Voyage</div>-->
<!--              </div>-->

<!--              <div class="flex mt-1" v-if="item.body.subscriptionData.product.categorie === 'MRH' || item.body.subscriptionData.product.categorie === 'Habitation'">-->
<!--                <img src="../../../assets/icons/mrh.svg" alt="" class="h-4 w-4 mr-2">-->
<!--                <div class="type">Assurance Mrh</div>-->
<!--              </div>-->

              <div class="flex mt-1 resultat">
                <div class="activeStatus"> Active </div>
<!--                <div class="inactiveStatus" v-if="item.status === 'PAID' || item.paymentStatus === 'PAID'">Payé</div>-->
<!--                <div class="activeStatus" v-if="item.status === 'PENDING'">Traitement en cours</div>-->
<!--                <div class="activeStatus" v-if="item.status === 'DONE' && item.paymentStatus !== 'PAID'">Reccevable</div>-->
              </div>
            </div>
          </div>

          <div class="nom w-1/3">
            <div class="flex mt-1 resultat">
              Montant
            </div>
            <div class="mt-2 amount">
<!--              <span>0</span>-->
              <span>1 700</span> Fr
            </div>
          </div>
        </div>

        <div class="nom w-full mt-3">
          <div class="button flex w-full">
            <div class="mr-2">
              Détails
            </div>
            <svg-icon
              name="chevron-right"
              class="h-4 "
              original
              color="white"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
    }
  },

  methods: {
    expireDate (date) {
      const now = new Date().getTime()
      const temps = now - parseInt(new Date(date).getTime())
      const jours = temps / (1000 * 3600 * 24)
      return jours.toFixed(0)
    },

    selected () {
      this.$emit('oga', this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.nom{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  color: #000000;
}
div{
  font-family: $font-default;
}
.type{
  font-family: $font-default;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.mobile{
  display: none;
}
.expire{
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #909090;
}
.all{
  background: #FFFFFF;
  border-radius: 20px;
}
.all:hover{
  box-shadow: 2px -1px 9px 0px #00000033;
}
.sous{
  font-weight: bold;
  font-size: 18px;
  line-height: 160%;
  color: #000000;
}

.resultat{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #909090;
  font-family: $popins;
}

.enonce{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: $font-default;
  color: #000000;
}

.button{
  height: 50px;
  background: #0269AD;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 144%;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  border: none;
}
.button:hover{
  background-color: $hover-button;
}
.activeStatus{
  color: #00BF53;
}
.inactiveStatus{
  color: #FF787F;
}
.svg-fill{
  fill: #FBD4D6;
}

@media only screen and (max-width: 700px) {
  .mobile{
    display: block;
  }
  .web{
    display: none;
  }
  .button{
    height: 43px;
    font-size: 14px;
    width: 100%;
    border-radius: 8.50932px;
  }
  .all{
    padding: 15px 25px 25px 25px !important;
  }
  .nom{
    font-size: 20px;
  }
  .expire{
    font-size: 13px;
    margin-top: 0px !important;
  }
  img{
    margin-top: 0px !important;
  }
  .amount{
    font-size: 16px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .all{
    padding: 20px !important;
  }
  .nom{
    font-size: 20px;
  }
  div{
    font-family: 'Mulish';
  }
  .expire{
    font-size: 15px;
  }
  .sous{
    font-size: 14px;
  }

  .resultat{
    font-size: 10px;
  }

  .enonce{
    font-size: 12px;
  }

  .button{
    height: 30.64px;
    font-size: 13.7298px;
    width: 100%;
    text-align: center;
    .ico{
      display: none;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){

}
</style>
