<template>
  <div class="about-page w-full h-screen">
    <popupCharge :want-to-make-offer="activeCharge"/>

    <div class="containerStyle mr-auto ml-auto">
      <div class="flex items-center cursor-pointer" @click="retour">
        <svg-icon
          name="back"
          class="h-16 mr-4 ico"
          original
        />
        <div class="titre">Portefeuille</div>
      </div>

      <div class="baniere md:flex items-center p-6 mt-5">
        <div class="md:w-2/3">
          <div class="enonce">Solde disponible</div>
          <div class="reponse mt-2">243 620 Fr</div>
        </div>

        <div class="md:flex justify-center md:w-1/3 w-full ">
          <div class="md:mt-0">
            Contrats rélisés : <span class="font-bold">2</span>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <div class="text-c18 mt-2 font-semibold text-black">Historique des transactions</div>
      </div>

      <div class="mt-5 liste">
        <transactionListe v-for="(item, index) in 6" :key="index" :item="item" class="mb-3"/>

        <div v-if="1 === 0" class="sous-titre text-center mt-6"> Aucune souscription trouvée</div>
      </div>
    </div>
  </div>
</template>

<script>
import transactionListe from '@/components/cedro/portefeuille/transactionListe'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import popupCharge from '@/components/popup/popupCharge'

export default {
  name: 'index',
  components: {
    transactionListe,
    popupCharge
  },
  data () {
    return {
      activDetail: false,
      selected: null,
      activeOption: false,
      search: null,
      allPrime: [
      ],
      total: 0,
      selectedItem: null,
      activeCharge: false,
      activeProduct: false
    }
  },

  created () {
  },

  methods: {
    retourProduct (answer) {
      this.activeProduct = answer
    },

    expireDate (date) {
      const now = new Date().getTime()
      const temps = parseInt(new Date(date).getTime()) - now
      const jours = temps / (1000 * 3600 * 24)
      return jours.toFixed(0)
    },

    allSouscriptions () {
      http.get(apiroutes.baseURL + apiroutes.souscriptions)
        .then(response => {
          this.activeCharge = false
          this.allPrime = response.data
          this.calculePrime()
        })
        .catch(error => {
          this.activeCharge = false
          console.log('erreur')
          console.log(error)
          if (error.data.statusCode === 401) {
            this.$store.dispatch('logout', null)
            this.$router.push('/login')
          }
        })
    },

    goTo (index) {
      this.$router.push('/' + index)
    },

    retour () {
      this.$router.push('/souscription')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.baniere{
  width: 100%;
  border-radius: 20px;
  height: 159px;
  background: #0269AD;
  color: #FFFFFF;
  font-family: $rubik;
}
.defil{
  position: absolute;
  width: 25%;
}
.champ{
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  font-family: $rubik;
  color: black;
  height: 70px;
  input{
    color: black;
  }
}
.champ:focus-within {
  border-color: $focus-color;
}
.champ:hover {
  border-color: $hover-color;
}
.enonce{
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.5);
}
.reponse{
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #FFFFFF;
}

.about-page{
  width: 100%;
  height: 100%;
  font-family: $font-default;
  padding-bottom: 100px;
  min-height: 100vh;
}

.details{
  width: 66%;
}

.containerStyle{
  width: 71%;
}

.titre{
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #000000;
  font-family: $rubik
}

.button{
  height: 71px;
  background: #FFFFFF;
  border-radius: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 144%;
  letter-spacing: 0.04em;
  color: #000000;
  font-family: $font-default;
  border: none;
}

.search{
  font-family: $rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #909090;
}

.sous-titre{
  font-family: $rubik;
  font-style: normal;
  font-size: 20px;
  line-height: 21px;
  color: #909090;
}

.svg-fill{
  fill: white;
}

.button:hover{
  background-color: #e2fafd;
}

@media screen and (max-width: 700px){
  .about-page{
    width: 90%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .details{
    width: 100%;
  }
  .containerStyle{
    width: 100%;
  }
  .baniere{
    height: 182px;
    border-radius: 20px;
    padding: 30px !important;
    .enonce{
      font-size: 16.8px;
    }
    .reponse{
      font-size: 25.2px;
    }
  }

  .searchBox{
    width: 100% !important;
    margin-top: 10px !important;

    .champ{
      width: 100%;
      height: 55px;
    }
    input{
      font-size: 16px;
      color: black;
    }
  }
  .titre{
    font-size: 27.3375px;
  }
  .sous-titre{
    font-size: 16px;
  }
  .button{
    height: 49px;
    width: 100%;
    border-radius: 13.8028px;
    font-size: 14px;
  }
  .ico{
    height: 3rem;
  }
  .filtr{
    display: none;
  }
}
@media screen and (max-width: 1024px) and (min-width: 770px){
  .about-page{
    width: 90%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .details{
    width: 100%;
  }
  .defil{
    width: 23%;
  }
  .containerStyle{
    width: 100%;
  }
  .baniere{
    height: 132px;
    border-radius: 20px;
    padding: 30px !important;
    .enonce{
      font-size: 20.8px;
    }
    .reponse{
      font-size: 29.2px;
    }
  }

  .searchBox{
    width: 100% !important;

    .champ{
      width: 100%;
      height: 60px;
    }
    input{
      font-size: 18px;
      color: black;
    }
  }
  .prime{
    width: 50%;
  }
  .filtr{
    width: 100% !important;
    .champ{
      font-size: 16px;
      width: 100%;
      height: 55px;
    }
    .mr-2{
      margin-right: 0px !important;
    }
  }
  .titre{
    font-size: 27.3375px;
  }
  .sous-titre{
    font-size: 16px;
  }
  .button{
    height: 55px;
    width: 100%;
    border-radius: 13.8028px;
    font-size: 18px;
  }
  .ico{
    height: 3rem;
  }
  .filtZone{
    width: 50%;
  }
  .denom{
    width: 100%;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .about-page{
    width: 90%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .details{
    width: 100%;
  }
  .defil{
    width: 30%;
  }
  .containerStyle{
    width: 100%;
  }
  .baniere{
    height: 132px;
    border-radius: 20px;
    padding: 30px !important;
    .enonce{
      font-size: 18.8px;
    }
    .reponse{
      font-size: 27.2px;
    }
  }

  .searchBox{
    width: 100% !important;

    .champ{
      width: 100%;
      height: 55px;
    }
    input{
      font-size: 16px;
      color: black;
    }
  }
  .prime{
    width: 30%;
  }
  .filtr{
    width: 100% !important;
    .champ{
      font-size: 16px;
      width: 100%;
      height: 55px;
    }
    .mr-2{
      margin-right: 0px !important;
    }
  }
  .titre{
    font-size: 27.3375px;
  }
  .sous-titre{
    font-size: 16px;
  }
  .button{
    height: 49px;
    width: 100%;
    border-radius: 13.8028px;
    font-size: 14px;
  }
  .ico{
    height: 3rem;
  }
  .filtZone{
    width: 70%;
  }
  .denom{
    width: 100%;
  }
}

  @media screen and (max-width: 325px){
    .titre{
      font-size: 22px;
    }
    .ico{
      height: 2.4rem;
    }
}
</style>
